// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Button } from 'admin/common/components/Button/Button';
import {
    Box,
    Container,
    MessageBox,
} from 'common/components/EmptyView/Styles';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';
import { Link } from 'react-router-dom';

export interface IEmptyViewProps {
    icon?: string;
    hideIcon?: boolean;
    onButtonClick?: () => void;
    buttonText?: string;
    buttonTo?: React.ComponentProps<typeof Link>['to'];
    title: string;
    description?: string;
    customIcon?: React.ReactNode;
    className?: string;
}

export const EmptyView: React.FC<IEmptyViewProps> = ({
    className,
    onButtonClick,
    buttonText,
    buttonTo,
    title,
    description,
    icon,
    customIcon,
    hideIcon,
}) => {
    const buttonProps = buttonTo ?{
        component: Link,
        to: buttonTo,
    } : {
        onClick: onButtonClick,
    };

    return (
        <Container className={className}>
            <Box>
                {!hideIcon && (customIcon ? customIcon : <Icon size="128" name={icon} />)}
                <MessageBox>
                    <h3>
                        <Translate content={title}/>
                    </h3>
                    {description && (
                        <p>
                            <Translate content={description}/>
                        </p>
                    )}
                </MessageBox>
                {buttonText && (
                    <Button size="lg" type="submit" intent={INTENT_TYPE.PRIMARY} {...buttonProps}>
                        <Translate content={buttonText}/>
                    </Button>
                )}
            </Box>
        </Container>
    );
};
